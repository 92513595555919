@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value secondaryColor from colors;

.wrapper {
  display: flex;
  justify-content: center;
  margin: 32px 0;
}
