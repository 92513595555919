@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;

.tags {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.tag {
  padding: 2px 8px;
  font-size: 14px;
  border: solid 1px primaryColor;
  border-radius: 4px;
  color: primaryColor;
  margin-right: 8px;
}

.tag.solid {
  background: primaryColor;
  color: white;
}

.tag.color2 {
  color: secondaryColor;
  border-color: secondaryColor;
}

.tag.solid.color2 {
  background: secondaryColor;
  color: white;
}

.tag.color3 {
  color: tertiaryColor;
  border-color: tertiaryColor;
}

.tag.solid.color3 {
  background: tertiaryColor;
  color: white;
}

.tag.color4 {
  color: cuaternaryColor;
  border-color: cuaternaryColor;
}

.tag.solid.color4 {
  background: cuaternaryColor;
  color: white;
}
