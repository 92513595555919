@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);

.wrapper {
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  box-sizing: border-box;
  padding: 16px;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.label {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}

.input {
  padding: 16px;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

.loginLink {
  display: block;
  text-align: center;
  margin: 16px 0;
  font-weight: bold;
}

.successMessage {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: boxShadow;
  padding: 16px;
  margin: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.successIcon {
  color: primaryColor;
  margin-right: 16px;
}
