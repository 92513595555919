@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;
@value gray500 #929DAB;

.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  display: none;
}

.open {
  display: block;
}

.container {
  background: white;
  border-radius: 8px;
  width: 90%;
  margin: 25px auto;

  box-sizing: border-box;
  max-height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

.header {
  text-align: right;
  padding: 16px;
}

.content {
  flex: 1 1 auto;
  overflow: auto;
  padding: 16px;
  padding-top: 0;
}

.closeIcon {
  color: gray500;
}

@media mqMedium {
  .container {
    max-width: 600px;
  }
}
