@value colors: "../styles/colors.css";
@value primaryColor from colors;

.avatar {
  background-color: primaryColor;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
}

.avatarSm {
  font-size: 11px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.avatarMd {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.avatarLg {
  font-size: 24px;
  width: 56px;
  height: 56px;
  border-radius: 12px;
}
