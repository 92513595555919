@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value gray500 #929DAB;
@value gray300 #c2c7cd;
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.wrapper {
  margin: 16px;
}

.overview {
  text-align: center;
  margin-bottom: 36px;
}

.avatar {
  margin: 0 auto;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.details {
  color: gray500;
  font-size: 14px;
  font-weight: bold;
}

.label {
  color: primaryColor;
  font-weight: 600;
}

.form {
  margin-bottom: 36px;
}

.field {
  border: none;
  border-bottom: solid 1px gray300;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 0;
  background: none;
  box-shadow: none;
  font-size: 16px;
}

.input {
  padding: 16px;
  border: none;
  border-radius: 4px;
  width: 80%;
  box-sizing: border-box;
  margin: 16px auto;
  font-size: 16px;
}

.editButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  margin: 0 8px;
}

.languages {
  display: flex;
  margin: 8px 0;
  width: 250px;
}

.passwordWrapper {
  position: relative;
}

.changePasswordLink {
  position: absolute;
  right: 0;
  top: 16px;
}

@media mqMedium {
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
