@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value gray500 #929DAB;
@value success from colors;
@value danger from colors;

.player {
  display: flex;
  align-items: center;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.playerAvatar {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
}

.playerName {
  flex: 1 1 auto;
}

.playerRejected {
  text-decoration: line-through;
}

.details {
  color: gray500;
  font-size: 14px;
  font-weight: bold;
}

.removeIcon {
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  margin: 0 8px;
  color: gray500;
}

.acceptedIcon {
  margin-left: 16px;
  color: success;
}

.rejectedIcon {
  margin-left: 16px;
  color: danger;
}

.player:nth-of-type(2n) .playerAvatar {
  background-color: secondaryColor;
}

.player:nth-of-type(3n) .playerAvatar {
  background-color: tertiaryColor;
}

.player:nth-of-type(4n) .playerAvatar {
  background-color: cuaternaryColor;
}
