@value colors: "./styles/colors.css";
@value primaryColor from colors;
@value mediaQueries: "./styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.app {
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .app.open {
    overflow: hidden;
    height: 100vh;
  }

  .mainContent {
    transition: transform ease-in-out 0.3s;
  }

  .mainContent.open {
    transform: translateX(80%);
  }
}

@media mqMedium {
  .app {
    display: flex;
    height: 100vh;
  }

  .mainContent {
    height: 100%;
    overflow-y: scroll;
    flex: 1 1 auto;
  }
}
