@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;
@value mqXLarge from mediaQueries;

.menu {
  height: 100vh;
  position: absolute;
  width: 80%;
  z-index: 100;
  top: 0;
  background: primaryColor;
  padding: 40px;
  box-sizing: border-box;
  transform: translateX(-100%);
  transition: transform ease-in-out 0.3s;
}

.menu.open {
  transform: translateX(0);
}

.menuItem {
  color: white;
  font-size: 24px;
  margin-bottom: 16px;
  display: flex;
  text-decoration: none;
}

.menuItemIcon {
  display: none;
}

.menuItem.active .menuItemText {
  border-bottom: solid 3px secondaryColor;
}

@media mqMedium {
  .menu {
    position: relative;
    transform: translateX(0);
    width: 80px;
    margin: 16px;
    border-radius: 8px;
    height: calc(100% - 32px);
    box-shadow: boxShadow;
    padding: 40px 16px;
  }

  .menuItem {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .menuItemText {
    display: none;
    flex-shrink: 0;
  }

  .menuIconWrapper {
    margin: 0 auto;
  }

  .menuItemIcon {
    display: inline-block;
  }

  .menuItem.active .menuIconWrapper {
    border-bottom: solid 3px secondaryColor;
  }
}

@media mqXLarge {
  .menu {
    width: auto;
    padding: 40px;
  }

  .menuIconWrapper {
    margin: 0;
    margin-right: 16px;
  }

  .menuItemText {
    display: inline;
  }

  .menuItem.active .menuIconWrapper {
    border: none;
  }
}
