@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;

.radioButtons {
  display: flex;
  width: 100%;
  border-radius: 4px;
  background: primaryLight;
}

.radioButtons .radioButton {
  border: none;
}

.radioButton {
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: primaryColor;
  border: solid 2px primaryColor;
  flex: 1 1 auto;
}

.radioButton:hover {
  background-color: primaryLight;
}

.radioButtonSelected {
  background-color: primaryColor;
  color: white;
}

.radioButtonSelected:hover {
  background-color: primaryColor;
}

.color2 {
  color: secondaryColor;
  border-color: secondaryColor;
}

.radioButtonSelected.color2 {
  background-color: secondaryColor;
  color: white;
}

.color3 {
  color: tertiaryColor;
  border-color: tertiaryColor;
}

.radioButtonSelected.color3 {
  background-color: tertiaryColor;
  color: white;
}

.color4 {
  color: cuaternaryColor;
  border-color: cuaternaryColor;
}

.radioButtonSelected.color4 {
  background-color: cuaternaryColor;
  color: white;
}
