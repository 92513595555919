.teeTapp__datepicker__wrapper {
  width: auto;
}

.teeTapp__datepicker {
  border: none;
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
}

.teeTapp__datepicker .react-datepicker__header {
  background: #edf1f6;
  border-bottom: none;
  padding: 16px;
}

.teeTapp__datepicker .react-datepicker__day--keyboard-selected,
.teeTapp__datepicker .react-datepicker__month-text--keyboard-selected,
.teeTapp__datepicker .react-datepicker__quarter-text--keyboard-selected,
.teeTapp__datepicker .react-datepicker__year-text--keyboard-selected,
.teeTapp__datepicker .react-datepicker__day--selected {
  background-color: #3a237b;
}

.teeTapp__datepicker .react-datepicker__navigation-icon::before {
  border-color: #3a237b;
}

.teeTapp__datepicker .react-datepicker__navigation-icon--previous {
  right: -12px;
  top: 4px;
}

.teeTapp__datepicker .react-datepicker__navigation--next {
  right: 12px;
  top: 4px;
}

.teeTapp__datepicker .react-datepicker__month {
  padding: 8px;
}
