@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;

.button {
  border-radius: 4px;
  padding: 12px 24px;
  text-align: center;
  font-weight: 600;
  background: none;
  border: solid 2px primaryColor;
  font-size: 16px;
  cursor: pointer;
  color: primaryColor;
}

.primary {
  background-color: primaryColor;
  color: white;
}

.secondary {
  border: solid 2px primaryColor;
}

.block {
  display: block;
  width: 100%;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primary.colorSecondary {
  background-color: secondaryColor;
}

.secondary.colorSecondary {
  border-color: secondaryColor;
  color: secondaryColor;
}
