.checkboxes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  padding: 16px;
  margin: 0 16px;
}
