@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.wrapper {
  margin: 16px 16px 48px;
}

@media mqMedium {
  .wrapper {
    max-width: 600px;
    margin: 0 auto 48px;
  }
}
