@value colors: "../styles/colors.css";
@value primaryColor from colors;

.timer {
  font-size: 20px;
  font-weight: bold;
  color: primaryColor;
  padding: 8px;
}

.danger {
  color: #c72727;
}
