@value colors: "../styles/colors.css";
@value primaryColor from colors;

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.h4 {
  color: primaryColor;
}
