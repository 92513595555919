@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.container {
  margin: 16px;
  padding: 16px;
}

.logoContainer {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
}

.logoContainer svg {
  width: 80px;
  height: auto;
}

.inputGroup {
  margin-bottom: 24px;
}

.label {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}

.input {
  padding: 16px;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  flex-wrap: wrap;
}

.link {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

@media mqMedium {
  .container {
    max-width: 500px;
    margin: 0 auto;
  }

  .link {
    width: auto;
    text-align: left;
  }
}
