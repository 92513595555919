@value gray600 #707070;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;
@value mqLarge from mediaQueries;

.wrapper {
  margin: 16px 16px;
}

.container {
  margin: 0 0 24px;
}

.header {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.timer {
  flex-grow: 0;
  font-size: 18px;
  font-weight: bold;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin: 0px;
  flex: 1 1 auto;
}

.description {
  margin: 0 0 24px 0;
}

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-bottom: 24px;
}

.teeTimes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  margin: 16px 0;
}

.empty {
  text-align: center;
  padding: 24px 0;
  color: gray600;
}

.players {
  list-style: none;
  padding: 0;
  margin: 24px 0;
}

.button {
  margin: 16px 0;
}

.termsCheckbox {
  margin: 16px 8px 32px;
}

.inlineLink {
  display: inline;
  padding: 0;
}

.rules {
  margin: 8px;
  padding-left: 16px;
}

@media mqMedium {
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
  .teeTimes {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.resourceCheckboxesWrapper {
  margin: 16px 0;
}
