@value colors: "../../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);

.wrapper {
  padding: 32px 16px;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: flex-start;
}

.h3 {
  flex: 1 1 auto;
  font-size: 20px;
  margin: 0;
}

.tags {
  flex-shrink: 0;
}

.h4 {
  margin-bottom: 8px;
  color: primaryColor;
}

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-bottom: 24px;
}
