@value colors: "../styles/colors.css";
@value mediaQueries: "../styles/mediaQueries.css";
@value primaryColor from colors;
@value mqMedium from mediaQueries;

.header {
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  flex-grow: 1;
}

.rightContainer {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
}

.notifications {
  color: primaryColor;
  margin-right: 16px;
}

@media mqMedium {
  .header {
    margin-bottom: 0px;
  }

  .menuIcon {
    display: none;
  }
}
