@value colors: "../styles/colors.css";
@value primaryColor from colors;

.passwordFieldWrapper {
  position: relative;
}

.passwordFieldInput {
  padding: 16px;
  padding-right: 40px;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

.passowordFieldVisibility {
  position: absolute;
  top: 12px;
  right: 12px;
  color: primaryColor;
  cursor: pointer;
}
