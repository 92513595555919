@value colors: "../styles/colors.css";
@value primaryColor from colors;

@value borderRadius 8px;
@value gray500 #929DAB;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);

.wrapper {
  position: relative;
  width: auto;
}

.dropDown {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: boxShadow;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropDownIcon {
  color: primaryColor;
  margin-right: 8px;
}

.dropDownValue {
  flex: 1 1 auto;
}

.dropDownChevron {
  margin-left: 16px;
}

.list {
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 60px;
  box-shadow: boxShadow;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 2;
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.listItem {
  cursor: pointer;
  padding: 8px 16px;
}

.listItem:hover {
  background-color: #dce4ee;
}

.listItem.selected {
  font-weight: bold;
  color: primaryColor;
}
