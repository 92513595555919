@value colors: "../styles/colors.css";
@value primaryColor from colors;

.linkButton {
  font-weight: 600;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: primaryColor;
  text-decoration: underline;
}
