@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value gray500 #929DAB;
@value success from colors;
@value danger from colors;

.player {
  padding: 4px 0;
  display: flex;
  align-items: center;
}

.playerAvatar {
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.playerName {
  flex-grow: 1;
  flex-shrink: 0;
}

.playerRejected {
  text-decoration: line-through;
}

.playerDescription {
  color: gray500;
  font-size: 14px;
  font-weight: bold;
}

.check {
  color: success;
  margin-left: 16px;
}

.times {
  color: danger;
  margin-left: 16px;
}

.player:nth-of-type(2n) .playerAvatar {
  background-color: secondaryColor;
}

.player:nth-of-type(3n) .playerAvatar {
  background-color: tertiaryColor;
}

.player:nth-of-type(4n) .playerAvatar {
  background-color: cuaternaryColor;
}
