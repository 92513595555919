@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value tertiaryColor from colors;
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.label {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}

.requiredLabel {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}

.textLabel {
  font-weight: bold;
  color: primaryColor;
  display: block;
}

.field {
  margin-bottom: 12px;
}

.requiredLabel::after {
  content: "*";
  color: tertiaryColor;
  margin-left: 4px;
}

.wrapper {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  box-sizing: border-box;
  padding: 16px;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.loginLink {
  display: block;
  text-align: center;
  margin: 16px 0;
  font-weight: bold;
}

.input {
  padding: 16px;
  padding-right: 40px;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

.inputGroup {
  display: flex;
  align-items: center;
}

.inputGroup div {
  flex: 1 1 auto;
}

.inputGroup div:first-of-type {
  margin-right: 18px;
}

.birthdayField {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.divider {
  font-weight: bold;
  color: primaryColor;
  margin: 0 8px;
}


.birthdayField .input {
  margin-bottom: 0;
  padding: 16px;
  width: 60px;
}

.birthdayField .yearInput {
  width: 100px;
}

.submitButton {
  width: 100%;
  margin: 24px 0;
}

.required {
  outline: solid 1px tertiaryColor;
}

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  margin: 24px;
  display: flex;
  flex-direction: column;
}

@media mqMedium {
  .submitButton {
    width: auto;
    float: right;
  }
}
