@value colors: "../../styles/colors.css";
@value primaryColor from colors;

@value borderRadius 8px;
@value gray500 #929DAB;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);

/* ---------- DaySelector -------------- */

.daySelectorWrapper {
  border-radius: borderRadius;
  box-shadow: boxShadow;
  padding: 16px 8px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.monthSelectorWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.monthSelectorMonth {
  flex: 1 0 auto;
  text-align: center;
  text-transform: capitalize;
}

.daysWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.childrenWrapper {
  margin: 10px 0;
}

/* ---------- Day -------------- */

.dayContainer {
  flex: 1 1 auto;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

.daySelected {
  background: primaryColor;
  border-radius: borderRadius;
  color: white;
  box-shadow: boxShadow;
}

.dayDisabled {
  color: gray500;
}

.dayWeekDay {
  margin-bottom: 4px;
  color: gray500;
}

.dayDay {
  font-weight: bold;
}

/* ---------- Button -------------- */

.buttonIcon {
  border: none;
  padding: 8px;
  cursor: pointer;
  background: none;
  width: 32px;
  height: 32px;
  text-align: center;
}

.emptyButtonIcon {
  width: 32px;
  height: 32px;
}
