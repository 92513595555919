.wrapper {
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  box-sizing: border-box;
  padding: 16px;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.loginLink {
  display: block;
  text-align: center;
  margin: 16px 0;
  font-weight: bold;
}
