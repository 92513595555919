@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value gray500 #929DAB;
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.h4 {
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

.flexFill {
  flex: 1 1 auto;
}

.mainContent {
  flex-grow: 1;
}

.buttons {
  display: flex;
  margin-top: 16px;
  flex-grow: 0;
}

.button {
  flex: 1 1 auto;
  margin: 0 8px;
}

.button:first-child {
  margin-left: 0px;
}

.button:last-child {
  margin-right: 0px;
}
