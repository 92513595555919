@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

@media mqMedium {
  .reservations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
