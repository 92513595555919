@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);

.wrapper {
  position: relative;
}

.input {
  padding: 16px;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  font-size: 16px;
}

.input:focus {
  outline: none;
  box-shadow: boxShadow;
}

.players {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 45px;
  list-style: none;
  z-index: 1;
}

.player {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.playerAvatar {
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.player:nth-of-type(2n) .playerAvatar {
  background-color: secondaryColor;
}

.player:nth-of-type(3n) .playerAvatar {
  background-color: tertiaryColor;
}

.player:nth-of-type(4n) .playerAvatar {
  background-color: cuaternaryColor;
}
