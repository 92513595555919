@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value secondaryColor from colors;
@value tertiaryColor from colors;
@value cuaternaryColor from colors;

.checkbox {
  cursor: pointer;
}

.checkboxIcon {
  color: primaryColor;
  margin-right: 8px;
}

.checkboxLabel {
  cursor: pointer;
}

.unchecked,
.color2.unchecked,
.color3.unchecked,
.color4.unchecked {
  color: primaryLight;
}

.color2 {
  color: secondaryColor;
}

.color3 {
  color: tertiaryColor;
}

.color4 {
  color: cuaternaryColor;
}
