@value mediaQueries: "../styles/mediaQueries.css";
@value mqLarge from mediaQueries;

.wrapper {
  margin: 16px;
  max-width: 800px;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 16px;
}

@media mqLarge {
  .wrapper {
    margin: 16px auto;
  }
}
