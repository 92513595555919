@value colors: "../../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value gray500 #929DAB;
@value mediaQueries: "../../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.h4 {
  margin-bottom: 8px;
  color: primaryColor;
}

.comment {
  margin-bottom: 8px;
  padding: 8px;
}

.commentHeader {
  display: flex;
  font-size: 14px;
  margin-bottom: 4px;
}

.commentAuthor {
  font-weight: 600;
  color: primaryColor;
  margin-right: 8px;
}

.commentTime {
  flex: 1 1 auto;
}

.commentBody {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 8px;
}

.deleteIcon {
  color: gray500;
  padding: 0 8px;
  cursor: pointer;
}

.deleteIcon:hover {
  color: primaryColor;
}

.textarea {
  width: 100%;
  border: solid 1px primaryLight;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  padding: 12px;
  box-sizing: border-box;
}

.textarea:focus,
.textarea:active {
  border: solid 2px primaryColor;
  outline: none;
}

.submitButton {
  margin-left: auto;
  display: block;
}

@media mqMedium {
  .commentBody {
    background-color: #e8eef6;
  }
}
