@value colors: "../../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;
@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value gray500 #929DAB;
@value mediaQueries: "../../styles/mediaQueries.css";
@value mqMedium from mediaQueries;


.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
  flex: 1 1 auto;
  margin-top: 0;
}

.h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-right: 16px;
}

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}


.eventInfo {
  flex: 1 1 auto;
}

.eventInfoTop {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.inputGroup {
  margin-bottom: 16px;
}

.label {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}

.input {
  padding: 16px;
  border: solid 1px primaryLight;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

.inputInline {
  padding: 16px;
  border: solid 1px primaryLight;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 8px;
  font-size: 16px;
}

.textarea {
  width: 100%;
  border: solid 1px primaryLight;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  padding: 12px;
  box-sizing: border-box;
}

.dateWithTime {
  display: flex;
  align-items: center;
}

.timeSelector {
  margin-left: 16px;
}

.buttonRow {
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.button {
  margin-left: 16px;
}

.empty {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}