@value colors: "../styles/colors.css";
@value warning from colors;
@value warningBorder from colors;

.alert {
  border: solid 1px;
  padding: 8px;
  border-radius: 4px;
  margin: 16px 0;
}

.alert a {
  color: black;
  font-weight: bold;
}

.alertWarning {
  border-color: warningBorder;
  background-color: warning;
}
