@value colors: "../styles/colors.css";
@value primaryColor from colors;

.button {
  border-radius: 4px;
  padding: 12px 24px;
  text-align: center;
  font-weight: 600;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: primaryColor;
  text-decoration: none;
}

.primary {
  background-color: primaryColor;
  color: white;
}

.secondary {
  border: solid 2px primaryColor;
}

.block {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
