@value colors: "../styles/colors.css";
@value primaryColor from colors;

.label {
  font-weight: bold;
  color: primaryColor;
  margin-bottom: 8px;
  display: block;
}
.submitButton {
  margin-top: 24px;
}
