@value colors: "../styles/colors.css";
@value primaryColor from colors;
@value primaryLight from colors;

.tabs {
  list-style: none;
  margin: 32px 0;
  padding: 0;
}

.tabsFlex {
  display: flex;
}

.tab {
  flex: 1 1 auto;
  text-align: center;
  padding: 8px 48px;
  color: primaryColor;
  cursor: pointer;
  text-decoration: none;
}

.tab:hover {
  font-weight: bold;
}

.tabSelected {
  background: primaryLight;
  border-radius: 4px;
  font-weight: bold;
}
