@value boxShadow 0px 1px 24px -1px rgba(0, 0, 0, 0.3);
@value gray600 #707070;
@value mediaQueries: "../styles/mediaQueries.css";
@value mqMedium from mediaQueries;

.wrapper {
  margin: 16px;
}

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #3A2381;
  padding: 16px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.empty {
  text-align: center;
  padding: 24px 0;
  color: gray600;
}


.h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 8px;
}

.italic {
  color: gray600;
  font-style: italic;
}



/* Tee Times */
.teeTimes {
  padding: 60px 0 24px;
}

.teeTime {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.teeTimeSelected .card {
  background-color: white;
}

.timeSlot {
  font-weight: bold;
  width: 40px;
  margin-right: 16px;
}

.reservationLink {
  text-decoration: none;
  flex: 1 1 auto;
}

.card {
  padding: 16px;
  box-shadow: boxShadow;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 4px;
  width: 100%;
  color: black;
  box-sizing: border-box;
}

.players {
  margin-bottom: 4px;
}

.linkIcon {
  margin-right: 8px;
}

.desktopHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.desktopTabs {
  margin: 0 24px !important;
  min-width: 400px;
}

.resourceCheckboxes {
  margin: 16px 0;
}

@media mqMedium {
  .teeTimes {
    overflow: auto;
    padding-right: 20px;
    flex: 1 1 auto;
  }

  .reservationDetails {
    box-shadow: boxShadow;
    background: white;
    margin: 24px;
    width: 350px;
    border-radius: 8px;
    flex-shrink: 0;
  }

  .resourceCheckboxes {
    margin: 0;
  }
}


.availableSlot {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: #c9d5e2;
}

.availableSlotText {
  margin-bottom: 4px;
}